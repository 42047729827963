import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { PHOTO_GALLERY_ACTIONS } from "./action";
import { PhotoGalleryAPIService } from "../../services/photo-gallery/photo-data";
import { notification } from "antd";

const photoGalleryService = new PhotoGalleryAPIService();

export function* GET_PHOTO_GALLERY({ payload }) {
  const response = yield doApiCall(
    photoGalleryService.getPhotoGallery,
    payload,
    PHOTO_GALLERY_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: PHOTO_GALLERY_ACTIONS.SET_STATE,
      payload: { data },
    });
  }
}

export function* CREATE_PHOTO_GALLERY({ payload }) {
  const response = yield doApiCall(
    photoGalleryService.createPhotoGallery,
    payload,
    PHOTO_GALLERY_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data } = response;
    yield put({
      type: PHOTO_GALLERY_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({ message: "Data added successfully" });

    yield put({
      type: PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY,
      payload: {},
    });
  }
}

export function* GET_PHOTO_GALLERY_BY_ID({ payload }) {
  const response = yield doApiCall(
    photoGalleryService.getPhotoGalleryById,
    payload,
    PHOTO_GALLERY_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: PHOTO_GALLERY_ACTIONS.SET_STATE,
      payload: { photoGallery: data },
    });
  }
}

export function* UPDATE_PHOTO_GALLERY({ payload }) {
  const response = yield doApiCall(
    photoGalleryService.updatePhotoGallery,
    payload,
    PHOTO_GALLERY_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: PHOTO_GALLERY_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({ message: "Data updated successfully" });

    yield put({
      type: PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY,
      payload: {},
    });
  }
}

export function* DELETE_PHOTO_GALLERY({ payload }) {
  const response = yield doApiCall(
    photoGalleryService.deletePhotoGallery,
    payload,
    PHOTO_GALLERY_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    yield put({
      type: PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY,
      payload: {},
    });

    notification.success({ message: "Data Deleted successfully" });
  }
}

export function* photoGallerySaga() {
  yield all([
    takeEvery(PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY, GET_PHOTO_GALLERY),
    takeEvery(PHOTO_GALLERY_ACTIONS.CREATE_PHOTO_GALLERY, CREATE_PHOTO_GALLERY),
    takeEvery(
      PHOTO_GALLERY_ACTIONS.GET_PHOTO_GALLERY_BY_ID,
      GET_PHOTO_GALLERY_BY_ID
    ),
    takeEvery(PHOTO_GALLERY_ACTIONS.UPDATE_PHOTO_GALLERY, UPDATE_PHOTO_GALLERY),
    takeEvery(PHOTO_GALLERY_ACTIONS.DELETE_PHOTO_GALLERY, DELETE_PHOTO_GALLERY),
  ]);
}
