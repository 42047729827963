import { Row, Col, Divider, Avatar, List, Button, Modal, Input } from "antd";
import CustomerForm from "../components/forms/Customer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CUSTOMER_ACTIONS } from "../redux/customer/action";
import UpdatePasswordForm from "../components/forms/ChangePassword";
import UpdateSubscriptionForm from "../components/forms/UpdateSubscription";
import { AntDrawer } from "../components/drawer/Drawer";

function Customer() {
  const dispatch = useDispatch();
  const { Search } = Input;

  const [open, setOpen] = useState(false);
  const [customerArray, setCustomerArray] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isSubscriptionUpdateModalOpen, setIsSubscriptionUpdateModalOpen] =
    useState(false);
  const [search, setSearch] = useState("");

  const { data, customer } = useSelector(
    (reducers) => reducers.customerReducer
  );

  const loadData = () => {
    dispatch({
      type: CUSTOMER_ACTIONS.GET_CUSTOMER,
      payload: {
        queryParams: {
          query: search,
        },
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [search]);

  useEffect(() => {
    setCustomerArray(data);
  }, [data]);

  useEffect(() => {
    setUpdateData(customer);
  }, [customer]);

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: CUSTOMER_ACTIONS.GET_CUSTOMER_BY_ID,
      payload: {
        path: {
          customerId: item.id,
        },
      },
    });

    setOpen(true);
  };

  const handleDelete = (item) => {
    dispatch({
      type: CUSTOMER_ACTIONS.DELETE_CUSTOMER,
      payload: {
        path: {
          customerId: item.id,
        },
      },
    });
  };

  const handleUpdateComplete = (item) => {
    setIsUpdate(item);
  };

  const showChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const handleChangePasswordOk = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleChangePasswordCancel = () => {
    setIsChangePasswordModalOpen(false);
  };

  const showSubscriptionUpdateModal = () => {
    setIsSubscriptionUpdateModalOpen(true);
  };

  const handleSubscriptionUpdateOk = () => {
    setIsSubscriptionUpdateModalOpen(false);
  };

  const handleSubscriptionUpdateCancel = () => {
    setIsSubscriptionUpdateModalOpen(false);
  };

  const onSearch = (e) => setSearch(e.target.value);

  const handleDrawerClose = () => {
    setOpen(false);
    setIsUpdate(false);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col
            xs="24"
            xl={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Customer
            </Button>
          </Col>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Divider orientation="left" plain>
                  Customers
                </Divider>
                <Search
                  placeholder="Search email"
                  value={search}
                  onChange={onSearch}
                  enterButton
                />
                <List
                  itemLayout="horizontal"
                  dataSource={customerArray}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => handleEdit(item)}>
                          Edit
                        </Button>,
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => handleDelete(item)}
                        >
                          Delete
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.image?.length ? item.image[0]?.preview : null
                            }
                          />
                        }
                        title={item.name}
                        description={item.designation}
                      />
                      {item.text}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        title="Change Password"
        open={isChangePasswordModalOpen}
        onOk={handleChangePasswordOk}
        onCancel={handleChangePasswordCancel}
        footer={null}
      >
        <UpdatePasswordForm userId={updateData?.id} />
      </Modal>
      <Modal
        title="Update Subscription"
        open={isSubscriptionUpdateModalOpen}
        onOk={handleSubscriptionUpdateOk}
        onCancel={handleSubscriptionUpdateCancel}
        footer={null}
      >
        <UpdateSubscriptionForm userId={updateData?.id} />
      </Modal>
      <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
        <CustomerForm
          updateData={updateData}
          isUpdate={isUpdate}
          isUpdateComplete={handleUpdateComplete}
        />
        {isUpdate && (
          <>
            <Divider orientation="left" plain>
              User Actions
            </Divider>
            <Button
              style={{ backgroundColor: "#36cfc9" }}
              onClick={showChangePasswordModal}
            >
              Update password
            </Button>
            <Button
              style={{ marginLeft: "20px", backgroundColor: "#36cfc9" }}
              onClick={showSubscriptionUpdateModal}
            >
              Update Subscription
            </Button>
          </>
        )}
      </AntDrawer>
    </>
  );
}

export default Customer;
