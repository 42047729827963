import { call, put } from "redux-saga/effects";
import { notification } from "antd";

import { history } from "../store";

export function* doApiCall(service, payload, ACTION_SET_STATE) {
  yield showLoading(ACTION_SET_STATE, true);
  yield put({ type: ACTION_SET_STATE, payload: { formError: {} } });
  try {
    yield showLoading(ACTION_SET_STATE, false);
    const response = yield call(service, payload);
    if (response.status === 204) {
      return { success: true };
    }
    return response.data;
  } catch (e) {
    yield showLoading(ACTION_SET_STATE, false);
    const { response } = e;
    if (response) {
      const { status, data } = response;
      if (status === 422) {
        yield put({
          type: ACTION_SET_STATE,
          payload: { formError: data.message.formErrors },
        });
      }
      if (status === 403) {
        yield history.push("/auth");
      }
    } else {
      notification.error({
        message: "Server Error",
        description: "Error reading response from server",
      });
    }
  }
  return { success: false };
}

export function* showLoading(action, loading) {
  yield put({ type: action, payload: { loading } });
}
