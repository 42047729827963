import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { PageDataAPIService } from "../../services/page-data/page-data";
import { doApiCall } from "../helper";
import { PAGE_DATA_ACTIONS } from "./action";

const pageDataService = new PageDataAPIService();

export function* GET_PAGE_DATA({ payload }) {
  const response = yield doApiCall(
    pageDataService.getPageData,
    payload,
    PAGE_DATA_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: PAGE_DATA_ACTIONS.SET_STATE,
      payload: {
        data,
      },
    });
  }
}

export function* CREATE_PAGE_DATA({ payload }) {
  const response = yield doApiCall(
    pageDataService.createPageData,
    payload,
    PAGE_DATA_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data } = response;
    yield put({
      type: PAGE_DATA_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({ message: "Data added successfully" });
  }
}

export function* GET_PAGE_DATA_BY_ID({ payload }) {
  const response = yield doApiCall(
    pageDataService.getPageDataById,
    payload,
    PAGE_DATA_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: PAGE_DATA_ACTIONS.SET_STATE,
      payload: {
        pageData: data,
      },
    });
  }
}

export function* UPDATE_PAGE_DATA({ payload }) {
  const response = yield doApiCall(
    pageDataService.updatePageData,
    payload,
    PAGE_DATA_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: PAGE_DATA_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({ message: "Data updated successfully" });
  }
}

export function* pageDataSaga() {
  yield all([
    takeEvery(PAGE_DATA_ACTIONS.GET_PAGE_DATA, GET_PAGE_DATA),
    takeEvery(PAGE_DATA_ACTIONS.CREATE_PAGE_DATA, CREATE_PAGE_DATA),
    takeEvery(PAGE_DATA_ACTIONS.GET_PAGE_DATA_BY_ID, GET_PAGE_DATA_BY_ID),
    takeEvery(PAGE_DATA_ACTIONS.UPDATE_PAGE_DATA, UPDATE_PAGE_DATA),
  ]);
}
