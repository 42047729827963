import { Button, Checkbox, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOPIC_ACTIONS } from "../../redux/topic/action";
import ImageUploader from "../ImageUploader";

const TopicForm = ({
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [chapterDataList, setChapterDataList] = useState([]);
  const dispatch = useDispatch();

  const { data: chapterDataArray } = useSelector(
    (reducers) => reducers.chapterReducer
  );

  const { formError = {} } = useSelector((reducers) => reducers.topicReducer);

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        name: updateData?.name,
        chapterId: updateData.chapterId,
        slug: updateData.slug,
        webVrSlug: updateData.webVrSlug,
        isFree: updateData.isFree,
      });
      setImageData(updateData?.image);
    }
    if (!isUpdate) {
      form.setFieldsValue({
        name: "",
        chapterId: "",
        slug: "",
        webVrSlug: "",
        isFree: false,
      });
    }
  }, [updateData]);

  useEffect(() => {
    setChapterDataList(chapterDataArray);
  }, [chapterDataArray]);

  const onFinish = (values) => {
    if (isUpdate) {
      dispatch({
        type: TOPIC_ACTIONS.UPDATE_TOPIC,
        payload: {
          path: {
            topicId: updateData.id,
          },
          body: {
            name: values.name,
            image: imageData[0]?.key,
            chapterId: values.chapterId,
            slug: values.slug,
            webVrSlug: values.webVrSlug,
            isFree: values.isFree,
          },
        },
      });
      form.setFieldsValue({
        name: "",
        chapterId: "",
        slug: "",
        webVrSlug: "",
        isFree: false,
      });
      setImageData([]);
      setFlip(!flip);
      isUpdateComplete(!isUpdate);
    } else {
      dispatch({
        type: TOPIC_ACTIONS.CREATE_TOPIC,
        payload: {
          body: {
            name: values.name,
            image: imageData[0]?.key,
            chapterId: values.chapterId,
            slug: values.slug,
            webVrSlug: values.webVrSlug,
            isFree: values.isFree,
          },
        },
      });
      form.setFieldsValue({
        name: "",
        chapterId: "",
        slug: "",
        webVrSlug: "",
        isFree: false,
      });
    }
  };

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          required
          validateStatus={formError?.name?.length ? "error" : "success"}
          help={formError?.name?.length ? formError.name[0] : ""}
        >
          <Input placeholder="Input name" />
        </Form.Item>

        <Form.Item
          name="chapterId"
          label="Chapter"
          required
          validateStatus={formError?.chapterId?.length ? "error" : "success"}
          help={formError?.chapterId?.length ? formError.chapterId[0] : ""}
        >
          <Select placeholder="Select Chapter">
            {chapterDataList?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Upload Avatar"
          name="imageLink"
          required
          validateStatus={formError?.imageLink?.length ? "error" : "success"}
          help={formError?.imageLink?.length ? formError.imageLink[0] : ""}
        >
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Form.Item>

        <Form.Item
          label="Slug"
          name="slug"
          required
          validateStatus={formError?.slug?.length ? "error" : "success"}
          help={formError?.slug?.length ? formError.slug[0] : ""}
        >
          <Input placeholder="Input slug" />
        </Form.Item>

        <Form.Item
          label="webVR Slug"
          name="webVrSlug"
          required
          validateStatus={formError?.webVrSlug?.length ? "error" : "success"}
          help={formError?.webVrSlug?.length ? formError.webVrSlug[0] : ""}
        >
          <Input placeholder="Input webVr slug" />
        </Form.Item>

        <Form.Item
          label="Free Topic?"
          name="isFree"
          valuePropName="checked"
          required
          validateStatus={formError?.isFree?.length ? "error" : "success"}
          help={formError?.isFree?.length ? formError.isFree[0] : ""}
        >
          <Checkbox>Free topic?</Checkbox>
        </Form.Item>

        <Form.Item>
          {isUpdate ? (
            <>
              <Button
                type="dashed"
                style={{ backgroundColor: "#f5c542" }}
                htmlType="submit"
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default TopicForm;
