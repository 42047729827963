import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SUBSCRIPTION_ACTIONS } from "../../redux/subscription/action";
import ImageUploader from "../ImageUploader";

const SubscriptionForm = ({
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
}) => {
  const [form] = Form.useForm();
  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        name: updateData?.name,
        price: updateData?.price,
      });
      setImageData(updateData?.image);
    }
  }, [updateData]);

  const { formError = {} } = useSelector((reducers) => reducers.subjectReducer);

  const onFinish = (values) => {
    if (isUpdate) {
      dispatch({
        type: SUBSCRIPTION_ACTIONS.UPDATE_SUBSCRIPTION,
        payload: {
          path: {
            subscriptionId: updateData.id,
          },
          body: {
            name: values.name,
            price: +values.price,
            image: values.imageData[0]?.key,
          },
        },
      });
      isUpdateComplete(!isUpdate);
    } else {
      dispatch({
        type: SUBSCRIPTION_ACTIONS.CREATE_SUBSCRIPTION,
        payload: {
          body: {
            name: values.name,
            price: +values.price,
            image: values.imageData[0]?.key,
          },
        },
      });
    }
  };

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          required
          validateStatus={formError?.name?.length ? "error" : "success"}
          help={formError?.name?.length ? formError.name[0] : ""}
        >
          <Input placeholder="Input name" />
        </Form.Item>

        <Form.Item
          label="Price"
          name="price"
          required
          validateStatus={formError?.price?.length ? "error" : "success"}
          help={formError?.price?.length ? formError.price[0] : ""}
        >
          <Input placeholder="Input price" />
        </Form.Item>

        <Form.Item
          label="Upload Avatar"
          name="imageLink"
          required
          validateStatus={formError?.imageLink?.length ? "error" : "success"}
          help={formError?.imageLink?.length ? formError.imageLink[0] : ""}
        >
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Form.Item>

        <Form.Item>
          {isUpdate ? (
            <>
              <Button
                type="dashed"
                style={{ backgroundColor: "#f5c542" }}
                htmlType="submit"
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default SubscriptionForm;
