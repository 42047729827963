import { Row, Col, Divider, Avatar, List, Button } from "antd";
import SubscriptionForm from "../components/forms/Subscription";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SUBSCRIPTION_ACTIONS } from "../redux/subscription/action";
import { AntDrawer } from "../components/drawer/Drawer";

function Subscription() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [subscriptionArray, setSubscriptionArray] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const { data, subscription, loading } = useSelector(
    (reducers) => reducers.subscriptionReducer
  );

  const loadData = () => {
    dispatch({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION,
      payload: {
        queryParams: {
          type: "TEXT",
        },
      },
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setSubscriptionArray(data);
  }, [data]);

  useEffect(() => {
    setUpdateData(subscription);
  }, [subscription]);

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_BY_ID,
      payload: {
        path: {
          subscriptionId: item.id,
        },
      },
    });

    setOpen(true);
  };

  const handleDelete = (item) => {
    dispatch({
      type: SUBSCRIPTION_ACTIONS.DELETE_SUBSCRIPTION,
      payload: {
        path: {
          subscriptionId: item.id,
        },
      },
    });
  };

  const handleUpdateComplete = (item) => {
    setIsUpdate(item);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col
            xs="24"
            xl={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Subscripiton
            </Button>
          </Col>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Divider orientation="left" plain>
                  Subscriptions
                </Divider>
                <List
                  itemLayout="horizontal"
                  dataSource={subscriptionArray}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => handleEdit(item)}>
                          Edit
                        </Button>,
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => handleDelete(item)}
                        >
                          Delete
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={<Avatar src={item.image[0].preview} />}
                        title={item.name}
                        description={item.designation}
                      />
                      {item.text}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
        <SubscriptionForm
          updateData={updateData}
          isUpdate={isUpdate}
          isUpdateComplete={handleUpdateComplete}
        />
      </AntDrawer>
    </>
  );
}

export default Subscription;
