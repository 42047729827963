import { Row, Col, Divider, Avatar, List, Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIDEO_ACTIONS } from "../redux/video/action";
import { CHAPTER_ACTIONS } from "../redux/chapter/action";
import VideoForm from "../components/forms/Video";
import { AntDrawer } from "../components/drawer/Drawer";

function Videos() {
  const dispatch = useDispatch();
  const { Search } = Input;

  const [open, setOpen] = useState(false);
  const [videoArray, setVideosArray] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const [search, setSearch] = useState("");

  const { data, video, loading } = useSelector(
    (reducers) => reducers.videoReducer
  );

  useEffect(() => {
    const loadData = () => {
      dispatch({
        type: VIDEO_ACTIONS.GET_VIDEO,
        payload: {
          queryParams: {
            query: search,
          },
        },
      });
    };
    loadData();
  }, [search, dispatch]);

  useEffect(() => {
    setVideosArray(data);
  }, [data]);

  useEffect(() => {
    setUpdateData(video);
  }, [video]);

  const handleEdit = (item) => {
    dispatch({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {},
    });

    setIsUpdate(true);

    dispatch({
      type: VIDEO_ACTIONS.GET_VIDEO_BY_ID,
      payload: {
        path: {
          id: item.id,
        },
      },
    });

    setOpen(true);
  };

  const handleDelete = (item) => {
    dispatch({
      type: VIDEO_ACTIONS.DELETE_VIDEO,
      payload: {
        path: {
          id: item.id,
        },
      },
    });
  };

  const handleUpdateComplete = (item) => {
    setIsUpdate(item);
  };

  const onSearch = (e) => setSearch(e.target.value);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col
            xs="24"
            xl={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Video
            </Button>
          </Col>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Divider orientation="left" plain>
                  Videos
                </Divider>
                <Search
                  placeholder="Search video"
                  value={search}
                  onChange={onSearch}
                  enterButton
                />
                <List
                  itemLayout="horizontal"
                  dataSource={videoArray}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => handleEdit(item)}>
                          Edit
                        </Button>,
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => handleDelete(item)}
                        >
                          Delete
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.image?.length ? item.image[0]?.preview : null
                            }
                          />
                        }
                        title={item.name}
                        description={item.designation}
                      />
                      {item.text}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
        <VideoForm
          updateData={updateData}
          isUpdate={isUpdate}
          isUpdateComplete={handleUpdateComplete}
        />
      </AntDrawer>
    </>
  );
}

export default Videos;
