import { Row, Col, Divider, Avatar, List, Button, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHAPTER_ACTIONS } from "../redux/chapter/action";
import ChapterForm from "../components/forms/Chapter";
import { SUBJECT_ACTIONS } from "../redux/subject/action";
import { AntDrawer } from "../components/drawer/Drawer";

function Chapters() {
  const dispatch = useDispatch();
  const { Search } = Input;

  const [open, setOpen] = useState(false);
  const [subjectsArray, setSubjectsArray] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const [search, setSearch] = useState("");

  const { data, chapter, loading } = useSelector(
    (reducers) => reducers.chapterReducer
  );

  const loadData = () => {
    dispatch({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {
        queryParams: {
          query: search,
        },
      },
    });

    dispatch({
      type: SUBJECT_ACTIONS.GET_SUBJECT,
      payload: {
        queryParams: {
          page: 1,
          perPage: 100000,
        },
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [search]);

  useEffect(() => {
    setSubjectsArray(data);
  }, [data]);

  useEffect(() => {
    setUpdateData(chapter);
  }, [chapter]);

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: CHAPTER_ACTIONS.GET_CHAPTER_BY_ID,
      payload: {
        path: {
          chapterId: item.id,
        },
      },
    });

    setOpen(true);
  };

  const handleDelete = (item) => {
    dispatch({
      type: CHAPTER_ACTIONS.DELETE_CHAPTER,
      payload: {
        path: {
          chapterId: item.id,
        },
      },
    });
  };

  const handleUpdateComplete = (item) => {
    setIsUpdate(item);
  };

  const onSearch = (e) => setSearch(e.target.value);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col
            xs="24"
            xl={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Chapter
            </Button>
          </Col>
          <Col xs="24" xl={24}>
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Divider orientation="left" plain>
                  Chapters
                </Divider>
                <Search
                  placeholder="Search subject"
                  value={search}
                  onChange={onSearch}
                  enterButton
                />
                <List
                  itemLayout="horizontal"
                  dataSource={subjectsArray}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <Button type="link" onClick={() => handleEdit(item)}>
                          Edit
                        </Button>,
                        <Button
                          type="link"
                          style={{ color: "red" }}
                          onClick={() => handleDelete(item)}
                        >
                          Delete
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.image?.length ? item.image[0]?.preview : null
                            }
                          />
                        }
                        title={item.name}
                        description={item.designation}
                      />
                      {item.text}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
        <ChapterForm
          updateData={updateData}
          isUpdate={isUpdate}
          isUpdateComplete={handleUpdateComplete}
        />
      </AntDrawer>
    </>
  );
}

export default Chapters;
