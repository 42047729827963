import axiosClient from "../client";
import { VIDEO_API_ROUTES } from "./constants";
import * as qs from "querystring";

export class VideoAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getVideos = async ({ queryParams }) => {
    return this.client.get(
      `${VIDEO_API_ROUTES.GET_VIDEO}?${qs.stringify(queryParams)}`
    );
  };

  getVideoById = async ({ path }) =>
    await this.client.get(
      VIDEO_API_ROUTES.GET_VIDEO_BY_ID.replace(":id", path.id)
    );

  createVideo = async ({ body }) =>
    await this.client.post(VIDEO_API_ROUTES.CREATE_VIDEO, body);

  updateVideo = async ({ path, body }) =>
    await this.client.patch(
      VIDEO_API_ROUTES.UPDATE_VIDEO.replace(":id", path.id),
      body
    );

  deleteVideo = async ({ path }) => {
    return await this.client.delete(
      `${VIDEO_API_ROUTES.DELETE_VIDEO.replace(":id", path.id)}`
    );
  };
}
