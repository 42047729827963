/* eslint-disable react/prop-types */

import { Drawer } from "antd";

export const AntDrawer = ({
  title,
  open,
  onClose,
  children,
  size = "default",
}) => {
  return (
    <Drawer size={size} title={title} open={open} onClose={onClose}>
      {children}
    </Drawer>
  );
};
