import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { SUBJECT_ACTIONS } from "./action";
import { notification } from "antd";
import { SubjectAPIService } from "../../services/subject/subject";

const subjectService = new SubjectAPIService();

export function* GET_SUBJECT({ payload }) {
  const response = yield doApiCall(
    subjectService.getSubject,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: SUBJECT_ACTIONS.SET_STATE,
      payload: { data },
    });
  }
}

export function* CREATE_SUBJECT({ payload }) {
  const response = yield doApiCall(
    subjectService.createSubject,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: SUBJECT_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Subject added successfully",
    });

    yield put({
      type: SUBJECT_ACTIONS.GET_SUBJECT,
      payload: {},
    });
  }
}

export function* GET_SUBJECT_BY_ID({ payload }) {
  const response = yield doApiCall(
    subjectService.getSubjectById,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: SUBJECT_ACTIONS.SET_STATE,
      payload: { subject: response },
    });
  }
}

export function* UPDATE_SUBJECT({ payload }) {
  const response = yield doApiCall(
    subjectService.updateSubject,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: SUBJECT_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Subject updated successfully",
    });

    yield put({
      type: SUBJECT_ACTIONS.GET_SUBJECT,
      payload: {},
    });
  }
}

export function* DELETE_SUBJECT({ payload }) {
  const response = yield doApiCall(
    subjectService.deleteSubject,
    payload,
    SUBJECT_ACTIONS.SET_STATE
  );

  if (response) {
    yield put({
      type: SUBJECT_ACTIONS.GET_SUBJECT,
      payload: {},
    });

    notification.success({
      message: "Subject deleted successfully",
    });

    yield put({
      type: SUBJECT_ACTIONS.GET_SUBJECT,
      payload: {},
    });
  }
}

export function* subjectSaga() {
  yield all([
    takeEvery(SUBJECT_ACTIONS.GET_SUBJECT, GET_SUBJECT),
    takeEvery(SUBJECT_ACTIONS.GET_SUBJECT_BY_ID, GET_SUBJECT_BY_ID),
    takeEvery(SUBJECT_ACTIONS.CREATE_SUBJECT, CREATE_SUBJECT),
    takeEvery(SUBJECT_ACTIONS.UPDATE_SUBJECT, UPDATE_SUBJECT),
    takeEvery(SUBJECT_ACTIONS.DELETE_SUBJECT, DELETE_SUBJECT),
  ]);
}
