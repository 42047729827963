import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLASS_ACTIONS } from "../../redux/class/action";
import ImageUploader from "../ImageUploader";

const ClassForm = ({
  video = false,
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);

  const { formError = {} } = useSelector((reducers) => reducers.classReducer);

  const dispatch = useDispatch();
  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        name: updateData?.name,
        description: updateData?.description,
      });
      setImageData(updateData?.image);
    }
    if (!isUpdate) {
      form.setFieldsValue({
        name: "",
        description: "",
      });
    }
  }, [updateData]);

  const onFinish = (values) => {
    if (isUpdate) {
      dispatch({
        type: CLASS_ACTIONS.UPDATE_CLASS,
        payload: {
          path: {
            classId: updateData.id,
          },
          body: {
            name: values.name,
            description: values.description,
            image: imageData[0]?.key,
          },
        },
      });
      form.setFieldsValue({
        name: "",
        description: "",
      });
      setImageData([]);
      setFlip(!flip);
      isUpdateComplete(!isUpdate);
    } else {
      dispatch({
        type: CLASS_ACTIONS.CREATE_CLASS,
        payload: {
          body: {
            name: values.name,
            description: values.description,
            image: imageData[0]?.key,
          },
        },
      });
      form.setFieldsValue({
        name: "",
        description: "",
      });
    }
  };

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          required
          validateStatus={formError?.name?.length ? "error" : "success"}
          help={formError?.name?.length ? formError.name[0] : ""}
        >
          <Input placeholder="Input name" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          required
          validateStatus={formError?.description?.length ? "error" : "success"}
          help={formError?.description?.length ? formError.description[0] : ""}
        >
          <Input.TextArea
            style={{ height: 120, resize: "none" }}
            placeholder="<p>...</p>"
          />
        </Form.Item>

        <Form.Item
          label="Upload Avatar"
          name="imageLink"
          required
          validateStatus={formError?.imageLink?.length ? "error" : "success"}
          help={formError?.imageLink?.length ? formError.imageLink[0] : ""}
        >
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Form.Item>

        <Form.Item>
          {isUpdate ? (
            <>
              <Button
                type="dashed"
                style={{ backgroundColor: "#f5c542" }}
                htmlType="submit"
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default ClassForm;
