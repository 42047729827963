import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHAPTER_ACTIONS } from "../../redux/chapter/action";
import ImageUploader from "../ImageUploader";

const ChapterForm = ({
  updateData = null,
  isUpdate = false,
  isUpdateComplete,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [flip, setFlip] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [subjectDataList, setSubjectDataList] = useState([]);
  const dispatch = useDispatch();

  const { data: subjectDataArray } = useSelector(
    (reducers) => reducers.subjectReducer
  );

  const { formError = {} } = useSelector((reducers) => reducers.chapterReducer);

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        name: updateData?.name,
        subjectId: updateData.subjectId,
      });
      setImageData(updateData?.image);
    }
    if (!isUpdate) {
      form.setFieldsValue({
        name: "",
        subjectId: "",
      });
    }
  }, [updateData]);

  useEffect(() => {
    setSubjectDataList(subjectDataArray);
  }, [subjectDataArray]);

  const onFinish = (values) => {
    if (isUpdate) {
      dispatch({
        type: CHAPTER_ACTIONS.UPDATE_CHAPTER,
        payload: {
          path: {
            chapterId: updateData.id,
          },
          body: {
            name: values.name,
            image: imageData[0]?.key,
            subjectId: values.subjectId,
          },
        },
      });
      form.setFieldsValue({
        name: "",
      });
      setImageData([]);
      setFlip(!flip);
      isUpdateComplete(!isUpdate);
    } else {
      dispatch({
        type: CHAPTER_ACTIONS.CREATE_CHAPTER,
        payload: {
          body: {
            name: values.name,
            image: imageData[0]?.key,
            subjectId: values.subjectId,
          },
        },
      });
      form.setFieldsValue({
        name: "",
        subjectId: "",
      });
    }
  };

  return (
    <>
      <Form layout={"vertical"} form={form} onFinish={onFinish}>
        <Form.Item
          label="Name"
          name="name"
          required
          validateStatus={formError?.name?.length ? "error" : "success"}
          help={formError?.name?.length ? formError.name[0] : ""}
        >
          <Input placeholder="Input name" />
        </Form.Item>

        <Form.Item
          name="subjectId"
          label="Subject"
          required
          validateStatus={formError?.subjectId?.length ? "error" : "success"}
          help={formError?.subjectId?.length ? formError.subjectId[0] : ""}
        >
          <Select placeholder="Select Subject">
            {subjectDataList?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Upload Avatar"
          name="imageLink"
          required
          validateStatus={formError?.imageLink?.length ? "error" : "success"}
          help={formError?.imageLink?.length ? formError.imageLink[0] : ""}
        >
          <ImageUploader
            fileData={imageData}
            setFileData={setImageData}
            flip={flip}
          />
        </Form.Item>

        <Form.Item>
          {isUpdate ? (
            <>
              <Button
                type="dashed"
                style={{ backgroundColor: "#f5c542" }}
                htmlType="submit"
              >
                Update
              </Button>
            </>
          ) : (
            <>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default ChapterForm;
