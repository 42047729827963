import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { SUBSCRIPTION_ACTIONS } from "./action";
import { SubscriptionAPIService } from "../../services/subscription/subscription";

const subscriptionService = new SubscriptionAPIService();

export function* GET_SUBSCRIPTION({ payload }) {
  const response = yield doApiCall(
    subscriptionService.getSubscription,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        data,
      },
    });
  }
}

export function* CREATE_SUBSCRIPTION({ payload }) {
  const response = yield doApiCall(
    subscriptionService.createSubscription,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data } = response;
    yield put({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({ message: "Subscription added successfully" });

    yield put({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* GET_SUBSCRIPTION_BY_ID({ payload }) {
  const response = yield doApiCall(
    subscriptionService.getSubscriptionById,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );
  if (response && response?.name) {
    yield put({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        subscription: response,
      },
    });
  }
}

export function* GET_SUBSCRIPTION_BY_USERID({ payload }) {
  const response = yield doApiCall(
    subscriptionService.getSubscriptionByUserId,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );
  if (response && response?.name) {
    yield put({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        subscription: response,
      },
    });
  }
}

export function* UPDATE_SUBSCRIPTION({ payload }) {
  const response = yield doApiCall(
    subscriptionService.updateSubscription,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );

  if (response && response?.data) {
    const { data } = response;
    yield put({
      type: SUBSCRIPTION_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({ message: "Subscription updated successfully" });

    yield put({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* UPDATE_USER_SUBSCRIPTION({ payload }) {
  const response = yield doApiCall(
    subscriptionService.updateUserSubscription,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );

  if (response && response.message) {
    notification.success({
      message: "Subscription updated",
      description: "Subscription updated successfully",
    });

    yield put({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_BY_USERID,
      payload: {
        path: {
          userId: payload.path.userId,
        },
      },
    });
  }
}

export function* DELETE_SUBSCRIPTION({ payload }) {
  const response = yield doApiCall(
    subscriptionService.deleteSubscription,
    payload,
    SUBSCRIPTION_ACTIONS.SET_STATE
  );
  if (response && response?.data) {
    yield put({
      type: SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success({ message: "Subscription Deleted successfully" });
  }
}

export function* subscriptionSaga() {
  yield all([
    takeEvery(SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION, GET_SUBSCRIPTION),
    takeEvery(SUBSCRIPTION_ACTIONS.CREATE_SUBSCRIPTION, CREATE_SUBSCRIPTION),
    takeEvery(
      SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_BY_ID,
      GET_SUBSCRIPTION_BY_ID
    ),
    takeEvery(
      SUBSCRIPTION_ACTIONS.GET_SUBSCRIPTION_BY_USERID,
      GET_SUBSCRIPTION_BY_USERID
    ),
    takeEvery(SUBSCRIPTION_ACTIONS.UPDATE_SUBSCRIPTION, UPDATE_SUBSCRIPTION),
    takeEvery(
      SUBSCRIPTION_ACTIONS.UPDATE_USER_SUBSCRIPTION,
      UPDATE_USER_SUBSCRIPTION
    ),
    takeEvery(SUBSCRIPTION_ACTIONS.DELETE_SUBSCRIPTION, DELETE_SUBSCRIPTION),
  ]);
}
