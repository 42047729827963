import {
  Row,
  Col,
  Typography,
  Divider,
  Form,
  Input,
  Button,
  List,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModelUploader from "../components/ModelUploader";
import { ASSET_LIBERARY_ACTIONS } from "../redux/asset-liberary/action";
import { SUBJECT_ACTIONS } from "../redux/subject/action";
import ModelViewer from "../components/ModelViewer";
import { AntDrawer } from "../components/drawer/Drawer";

function AssetLibrary() {
  const { Title } = Typography;
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [modelData, setModelData] = useState([]);
  const [listData, setListData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [flip, setFlip] = useState(false);
  const [assetLibraryData, setAssetLibraryData] = useState(null);
  const [subjectDataList, setSubjectDataList] = useState(null);

  const { data, assetLiberary } = useSelector(
    (reducers) => reducers.assetLiberaryReducer
  );

  const { data: subjectDataArray, subject } = useSelector(
    (reducers) => reducers.subjectReducer
  );

  const loadData = () => {
    dispatch({
      type: ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY,
    });
    dispatch({
      type: SUBJECT_ACTIONS.GET_SUBJECT,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setListData(data);
    setSubjectDataList(subjectDataArray);
  }, [data, subjectDataArray]);

  useEffect(() => {
    setAssetLibraryData(assetLiberary);
  }, [assetLiberary]);

  useEffect(() => {
    form.setFieldsValue({
      assetTitle: assetLibraryData?.name,
      assetDescription: assetLibraryData?.description,
      subject: assetLibraryData?.subjectId,
    });
    setModelData(assetLibraryData?.image);
  }, [assetLibraryData]);

  const onFinish = (values) => {
    console.log(values);
    const finalData = {
      name: values.assetTitle,
      description: values.assetDescription,
      image: modelData[0]?.key,
      subjectId: values.subject,
    };
    if (isUpdate) {
      dispatch({
        type: ASSET_LIBERARY_ACTIONS.UPDATE_ASSET_LIBERARY,
        payload: {
          path: {
            assetLiberaryId: assetLibraryData?.id,
          },
          body: {
            ...finalData,
          },
        },
      });
    } else {
      dispatch({
        type: ASSET_LIBERARY_ACTIONS.CREATE_ASSET_LIBERARY,
        payload: {
          body: {
            ...finalData,
          },
        },
      });
    }
    form.setFieldsValue({
      assetTitle: "",
      assetDescription: "",
      subject: "",
    });
    setModelData([]);
    setFlip(!flip);
    if (isUpdate) {
      setIsUpdate(false);
    }
  };

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: ASSET_LIBERARY_ACTIONS.GET_ASSET_LIBERARY_BY_ID,
      payload: {
        path: {
          assetLiberaryId: item.id,
        },
      },
    });

    setOpen(true);
  };

  const handleDelete = (item) => {
    dispatch({
      type: ASSET_LIBERARY_ACTIONS.DELETE_ASSET_LIBERARY,
      payload: {
        path: {
          assetLiberaryId: item.id,
        },
      },
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Row gutter={16}>
        <Col
          xs="24"
          xl={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button type="primary" onClick={() => setOpen(true)}>
            Add Asset
          </Button>
        </Col>
      </Row>

      <Divider orientation="left" plain>
        Image gallery
      </Divider>
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <List
            itemLayout="vertical"
            size="large"
            // pagination={{
            //   onChange: (page) => {
            //     console.log(page);
            //   },
            //   pageSize: 3,
            // }}
            dataSource={listData}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button type="link" onClick={() => handleEdit(item)}>
                    Edit
                  </Button>,
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => handleDelete(item)}
                  >
                    Delete
                  </Button>,
                ]}
                extra={<ModelViewer link={item.image[0].preview} />}
              >
                <List.Item.Meta title={item.name} />
                {item.description}
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
        <Col xs={24}>
          <ModelUploader
            fileData={modelData}
            setFileData={setModelData}
            flip={flip}
          />
        </Col>
        <Col xs={24}>
          <Form layout={"vertical"} form={form} onFinish={onFinish}>
            <Form.Item label="Asset Title" name="assetTitle">
              <Input placeholder="<h1>...</h1>" />
            </Form.Item>

            <Form.Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select placeholder="Select Subject">
                {subjectDataList?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Asset Description" name="assetDescription">
              <Input.TextArea
                style={{ height: 120, resize: "none" }}
                placeholder="<p>...</p>"
              />
            </Form.Item>
            <Form.Item>
              {isUpdate ? (
                <Button
                  type="dashed"
                  style={{ backgroundColor: "#f5c542" }}
                  htmlType="submit"
                >
                  Update
                </Button>
              ) : (
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </AntDrawer>
    </>
  );
}

export default AssetLibrary;
