import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { CHAPTER_ACTIONS } from "./action";
import { ChapterAPIService } from "../../services/chapter/chapters";

const chapterService = new ChapterAPIService();

export function* GET_CHAPTER({ payload }) {
  const response = yield doApiCall(
    chapterService.getChapters,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );

  if (response) {
    yield put({
      type: CHAPTER_ACTIONS.SET_STATE,
      payload: {
        data: response.data,
      },
    });
  }
}

export function* CREATE_CHAPTER({ payload }) {
  const response = yield doApiCall(
    chapterService.createChapter,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: CHAPTER_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Chapter added successfully",
    });

    yield put({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* GET_CHAPTER_BY_ID({ payload }) {
  const response = yield doApiCall(
    chapterService.getChapterById,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: CHAPTER_ACTIONS.SET_STATE,
      payload: {
        chapter: response,
      },
    });
  }
}

export function* UPDATE_CHAPTER({ payload }) {
  const response = yield doApiCall(
    chapterService.updateChapter,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: CHAPTER_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Chapter updated successfully",
    });

    yield put({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* DELETE_CHAPTER({ payload }) {
  const response = yield doApiCall(
    chapterService.deleteChapter,
    payload,
    CHAPTER_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success({
      message: "Chapter deleted successfully",
    });

    yield put({
      type: CHAPTER_ACTIONS.GET_CHAPTER,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* chapterSaga() {
  yield all([
    takeEvery(CHAPTER_ACTIONS.GET_CHAPTER, GET_CHAPTER),
    takeEvery(CHAPTER_ACTIONS.CREATE_CHAPTER, CREATE_CHAPTER),
    takeEvery(CHAPTER_ACTIONS.GET_CHAPTER_BY_ID, GET_CHAPTER_BY_ID),
    takeEvery(CHAPTER_ACTIONS.UPDATE_CHAPTER, UPDATE_CHAPTER),
    takeEvery(CHAPTER_ACTIONS.DELETE_CHAPTER, DELETE_CHAPTER),
  ]);
}
