import { notification } from "antd";
import { all, put, takeEvery } from "redux-saga/effects";
import { doApiCall } from "../helper";
import { VIDEO_ACTIONS } from "./action";
import { VideoAPIService } from "../../services/video/video";

const videoService = new VideoAPIService();

export function* GET_VIDEO({ payload }) {
  const response = yield doApiCall(
    videoService.getVideos,
    payload,
    VIDEO_ACTIONS.SET_STATE
  );

  if (response) {
    yield put({
      type: VIDEO_ACTIONS.SET_STATE,
      payload: {
        data: response.data,
      },
    });
  }
}

export function* CREATE_VIDEO({ payload }) {
  const response = yield doApiCall(
    videoService.createVideo,
    payload,
    VIDEO_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: VIDEO_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Video added successfully",
    });

    yield put({
      type: VIDEO_ACTIONS.GET_VIDEO,
      payload: {},
    });
  }
}

export function* GET_VIDEO_BY_ID({ payload }) {
  const response = yield doApiCall(
    videoService.getVideoById,
    payload,
    VIDEO_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: VIDEO_ACTIONS.SET_STATE,
      payload: {
        video: response,
      },
    });
  }
}

export function* UPDATE_VIDEO({ payload }) {
  const response = yield doApiCall(
    videoService.updateVideo,
    payload,
    VIDEO_ACTIONS.SET_STATE
  );

  if (response) {
    const { data } = response;
    yield put({
      type: VIDEO_ACTIONS.SET_STATE,
      payload: {
        ...data,
      },
    });

    notification.success({
      message: "Video updated successfully",
    });

    yield put({
      type: VIDEO_ACTIONS.GET_VIDEO,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* DELETE_VIDEO({ payload }) {
  const response = yield doApiCall(
    videoService.deleteVideo,
    payload,
    VIDEO_ACTIONS.SET_STATE
  );
  if (response) {
    yield put({
      type: VIDEO_ACTIONS.GET_VIDEO,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });

    notification.success({
      message: "Video deleted successfully",
    });

    yield put({
      type: VIDEO_ACTIONS.GET_VIDEO,
      payload: {
        queryParams: {
          type: payload.type,
        },
      },
    });
  }
}

export function* videoSaga() {
  yield all([
    takeEvery(VIDEO_ACTIONS.GET_VIDEO, GET_VIDEO),
    takeEvery(VIDEO_ACTIONS.CREATE_VIDEO, CREATE_VIDEO),
    takeEvery(VIDEO_ACTIONS.GET_VIDEO_BY_ID, GET_VIDEO_BY_ID),
    takeEvery(VIDEO_ACTIONS.UPDATE_VIDEO, UPDATE_VIDEO),
    takeEvery(VIDEO_ACTIONS.DELETE_VIDEO, DELETE_VIDEO),
  ]);
}
