import { Row, Col, Tabs, Divider, Avatar, List, Button } from "antd";
import TestimonialForm from "../components/forms/Testimonials";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TESTIMONIAL_ACTIONS } from "../redux/testimonial/action";
import { AntDrawer } from "../components/drawer/Drawer";

function Testimonials() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [testimonialDataArray, setTestimonialDataArray] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const { data, testimonial, loading } = useSelector(
    (reducers) => reducers.testimonialReducer
  );

  const loadData = () => {
    if (activeTab === "1") {
      dispatch({
        type: TESTIMONIAL_ACTIONS.GET_TESTIMONIAL,
        payload: {
          queryParams: {
            type: "TEXT",
          },
        },
      });
    } else {
      dispatch({
        type: TESTIMONIAL_ACTIONS.GET_TESTIMONIAL,
        payload: {
          queryParams: {
            type: "VIDEO",
          },
        },
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [activeTab]);

  useEffect(() => {
    setTestimonialDataArray(data);
  }, [data]);

  useEffect(() => {
    setUpdateData(testimonial);
  }, [testimonial]);

  const handleEdit = (item) => {
    setIsUpdate(true);

    dispatch({
      type: TESTIMONIAL_ACTIONS.GET_TESTIMONIAL_BY_ID,
      payload: {
        path: {
          testimonialId: item.id,
        },
      },
    });

    setOpen(true);
  };

  const handleDelete = (item) => {
    dispatch({
      type: TESTIMONIAL_ACTIONS.DELETE_TESTIMONIAL,
      payload: {
        path: {
          testimonialId: item.id,
        },
        type: activeTab === "1" ? "TEXT" : "VIDEO",
      },
    });
  };

  const handleUpdateComplete = (item) => {
    setIsUpdate(item);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pages = [
    {
      key: "1",
      label: "Text",
      children: (
        <>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Divider orientation="left" plain>
                Testimonials
              </Divider>
              <List
                itemLayout="horizontal"
                dataSource={testimonialDataArray}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button type="link" onClick={() => handleEdit(item)}>
                        Edit
                      </Button>,
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => handleDelete(item)}
                      >
                        Delete
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={item.image[0].preview} />}
                      title={item.name}
                      description={item.designation}
                    />
                    {item.text}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
            <TestimonialForm
              updateData={updateData}
              isUpdate={isUpdate}
              isUpdateComplete={handleUpdateComplete}
              activeTab={activeTab}
            />
          </AntDrawer>
        </>
      ),
    },
    {
      key: "2",
      label: "Video",
      children: (
        <>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Divider orientation="left" plain>
                Testimonials
              </Divider>
              <List
                itemLayout="horizontal"
                dataSource={testimonialDataArray}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button type="link" onClick={() => handleEdit(item)}>
                        Edit
                      </Button>,
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => handleDelete(item)}
                      >
                        Delete
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta title={item.image[0].key} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <AntDrawer size="large" open={open} onClose={handleDrawerClose}>
            <TestimonialForm
              video={activeTab === "2"}
              updateData={updateData}
              isUpdate={isUpdate}
              isUpdateComplete={handleUpdateComplete}
              activeTab={activeTab}
            />
          </AntDrawer>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col
            xs="24"
            xl={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Testimonials
            </Button>
          </Col>
          <Col xs="24" xl={24}>
            <Tabs
              defaultActiveKey="1"
              items={pages}
              onChange={handleTabChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Testimonials;
